import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Products from '../../../entities/Products';
import ProductsView from '../ProductsView/ProductsView';
import { useStyles } from './ProductsSearch.styles';

const PAGE_SIZE = 50;

const ProductsSearch = () => {
  const classes = useStyles();
  const productsAPI = new Products();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryValue = queryParams.get('q');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [items, setItems] = React.useState([]);
  const [totalItems, setTotalItems] = React.useState(0);

  const handleSearch = async (page) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });

    try {
      const productsSearchEngineBody = {
        searchValue: queryValue,
        from: (page - 1) * PAGE_SIZE,
        size: PAGE_SIZE,
      };

      const { data } = await productsAPI.productsSearchEngine(productsSearchEngineBody);

      setItems(data.items);
      setTotalItems(data.total);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    handleSearch(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  return (
    <Box component="div" className={classes.products}>
      <ProductsView key={queryValue} isRoot={false} topSellings={items} />
      <Pagination
        count={Math.ceil(totalItems / PAGE_SIZE)}
        page={currentPage}
        renderItem={(item) => (
          <PaginationItem slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }} {...item} />
        )}
        onChange={(_, page) => handleSearch(page)}
        shape="rounded"
        className={classes.pagination}
      />
    </Box>
  );
};

export default ProductsSearch;
