const Paths = {
  TSILanding: 'https://tsindustrial.com/',
  NotFound: '*',
  SignIn: '/signin',
  UserManagement: {
    global: '/user/:type',
    signin: '/user/signin',
    signup: '/user/signup',
    verifyNewAccount: '/user/verify',
    forgotPassword: '/user/forgot-password',
    resetPassword: '/user/reset-password',
  },
  client: {
    ClientSupport: '/client-support',
    MyPersonalInfo: '/my-data',
    MyAddresses: '/my-addresses',
    MyOrders: '/my-orders',
    MyAccount: '/my-account',
    LandingPage: '/information/:type',
    LandingPageScroll: '/information/:type/:scrollTo',
    DataForm: '/data-form/:type',
    SignUp: '/signup',
    SignIn: '/signin',
    VerifyAccount: '/verify-account',
    Home: '/',
    Home2: '/home',
    ProductsView: '/products',
    ProductsSearch: '/products/search',
    Checkout: '/checkout',
    MostSold: '/most-sold',
    Services: '/services',
    Galery: '/galery',
    Background: '/background',
    ContactUs: '/contact-us',
    About: '/about',
    Delivery: '/delivery',
    Support: '/support',
    ReturnsPolicy: '/returns-policy',
    CancelationsPolicy: '/cancelations-policy',
    Information: '/information/',
    HelpAndSupport: 'help-and-support',
    ReturnsAndClaims: 'returns-and-claims',
    Tracking: 'track-order',
  },
  admin: {
    Building: '/ts-admin/building',
    Home: '/ts-admin',
    Home2: '/ts-admin/home',
    AdminConfiguration: '/ts-admin/configuration',
    StoreProfile: '/ts-admin/configuration/store-profile',
    ForeingExchange: '/ts-admin/configuration/foreing-exchange',
    EmailSettings: '/ts-admin/configuration/email-settings',
    OrderSettings: '/ts-admin/configuration/order-settings',
    web: '/ts-admin/configuration/web',
    usersAndPermissions: '/ts-admin/configuration/users-and-permissions',
    Products: {
      view: '/ts-admin/products/view',
      add: '/ts-admin/products/add',
      edit: '/ts-admin/products/edit',
      search: '/ts-admin/products/search',
      import: '/ts-admin/products/import',
      export: '/ts-admin/products/export',
      categories: '/ts-admin/products/categories',
      newvariants: '/ts-admin/products/new-variants',
    },
    Orders: {
      view: '/ts-admin/orders/view',
      claims: '/ts-admin/orders/claims',
      add: '/ts-admin/orders/add',
      search: '/ts-admin/orders/search',
      export: '/ts-admin/orders/export',
      delivery: '/ts-admin/orders/delivery',
      followship: '/ts-admin/orders/followship',
      status: '/ts-admin/orders/status',
      viewOrder: '/ts-admin/order/',
    },
    Clients: {
      view: '/ts-admin/clients/view',
      add: '/ts-admin/clients/add',
      search: '/ts-admin/clients/search',
      import: '/ts-admin/clients/import',
      export: '/ts-admin/clients/export',
      groups: '/ts-admin/clients/groups',
      edit: '/ts-admin/clients/edit',
    },
    Integrations: {
      integrations: '/ts-admin/integrations',
      tvh: '/ts-admin/integrations/tvh',
      stripe: '/ts-admin/integrations/stripe',
      skydropx: '/ts-admin/integrations/skydropx',
    },
    Tvh: {
      ImportFiles: '/ts-admin/tvh/import-files',
      FindProduct: '/ts-admin/tvh/find-product',
      OrdersInProcess: '/ts-admin/tvh/orders-in-process',
      OrdersClosed: '/ts-admin/tvh/orders-closed',
      Tracking: '/ts-admin/tvh/tracking',
      Configuration: '/ts-admin/tvh/configuration',
      find: '/ts-admin/tvh/find',
    },
    Sideboard: {
      view: '/ts-admin/sideboard',
      LogoAndFavicon: '/ts-admin/sideboard/logo-and-favicon',
    },
    Users: {
      MyProfile: '/ts-admin/my-profile',
    },
    Marketing: {
      TransactionalEmails: '/ts-admin/marketing/transactional-emails',
      EmailTemplate: '/ts-admin/marketing/email-template',
    },
    AdminConfigurations: {
      markup: '/ts-admin/configuration/markup',
      freight: '/ts-admin/configuration/freight',
      weightsRules: '/ts-admin/configuration/weights-rules',
    },
    Security: {
      SecurityDashboard: '/ts-admin/security/dashboard',
      ListDetails: '/ts-admin/security/list-details/:type',
    },
  },
};

export default Paths;
