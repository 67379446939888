import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import TsiButton from '../Atoms/TsiButton';
import Products from '../../../../entities/Products';
import Font from '../../Fonts/Font';
import CircularProgressAttom from '../Atoms/CircularProgressAttom';
import PopperAttom from '../Atoms/PopperAttom';
import ResponsiveComponent from '../../../../Hooks/ResponsiveComponent';
import InputAttom from '../Atoms/InputAttom';
import Paths from '../../../../utils/enums/Paths';
import generalsEnums from '../../../../utils/Generals';
import { getResponsiveValues } from '../../../../utils/FormatData';
import * as colors from '../../../../assets/GlobalColors';

const Searcher = ({ anchorRef, searchValueCombo }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const productsAPI = new Products();
  const { t } = useTranslation('global');
  const { isXs, isSm, isMd, isLg, isXl } = ResponsiveComponent();
  const [searchValue, setSearchValue] = React.useState(generalsEnums.emptyString);
  const [disableButton, setDisableButton] = React.useState(generalsEnums.trueBoolean);
  const [openPopper, setOpenPopper] = React.useState(generalsEnums.falseBoolean);
  const [loading, setLoading] = React.useState(generalsEnums.falseBoolean);
  const [totalItems, setTotalItems] = React.useState(generalsEnums.zeroNumber);
  const [itemsToShow, setItemsToShow] = React.useState(generalsEnums.emptyArray);

  const responsiveArray = [
    ['', '100%', '100%', '100%', '800px'],
    [false, true, true, true, true],
    [10, 20, 40, 40, 60],
    [
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size12.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
      generalsEnums.fontSizes.size16.size,
    ],
    [
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size12.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
      generalsEnums.fontSizes.size16.lineHeight,
    ],
  ];
  const [rootWidth, rootDisplay, circularSize, circularFontSize, circularLineHeight] = getResponsiveValues(
    responsiveArray,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl
  );

  const handleShowMore = () => {
    setOpenPopper(generalsEnums.falseBoolean);
    navigate(`${Paths.client.ProductsSearch}?q=${encodeURIComponent(searchValue)}`);
  };

  const handleClickInput = () => {
    if (itemsToShow.length > 0) {
      setOpenPopper(generalsEnums.trueBoolean);
    }
  };

  const handleClickItem = (item) => {
    setTotalItems(generalsEnums.zeroNumber);
    setItemsToShow(generalsEnums.emptyArray);
    setSearchValue(generalsEnums.emptyString);
    setDisableButton(generalsEnums.trueBoolean);
    setOpenPopper(generalsEnums.falseBoolean);

    if (location.pathname !== `/product-detail/${item._id}`) navigate(`/product-detail/${item._id}`);
  };

  const handleOnChange = (value) => {
    setSearchValue(value);
    setItemsToShow(generalsEnums.emptyArray);
    setTotalItems(generalsEnums.zeroNumber);
    setOpenPopper(generalsEnums.falseBoolean);
  };

  const handleSearch = async () => {

    setOpenPopper(generalsEnums.trueBoolean);
    setLoading(generalsEnums.trueBoolean);
    setDisableButton(generalsEnums.trueBoolean);

    try {
      const productsSearchEngineBody = {
        searchValue: searchValue,
      };

      const { data } = await productsAPI.productsSearchEngine(productsSearchEngineBody);

      if (data.items.length === 0) {
        setOpenPopper(generalsEnums.falseBoolean);
        return;
      }

      setItemsToShow(data.items);
      setTotalItems(data.total - data.items.length);
    } catch (error) {
      setOpenPopper(generalsEnums.falseBoolean);
    }

    setLoading(generalsEnums.falseBoolean);
    setDisableButton(generalsEnums.falseBoolean);
  };

  React.useEffect(() => {
    if (searchValue === generalsEnums.emptyString) {
      setDisableButton(generalsEnums.trueBoolean);
    } else {
      setDisableButton(generalsEnums.falseBoolean);
    }
  }, [searchValue]);

  React.useEffect(() => {
    if(searchValueCombo !== "Select brand") setSearchValue(searchValueCombo)
  }, [setSearchValue, searchValueCombo]);

  return (
    <Box
      ref={anchorRef}
      component={generalsEnums.BoxDiv}
      sx={{
        width: rootWidth,
        display: rootDisplay ? 'flow' : 'none',
      }}
    >
      <InputAttom
        isSearcher={generalsEnums.trueBoolean}
        value={searchValue}
        setValue={handleOnChange}
        handleAction={handleSearch}
        onClickInput={handleClickInput}
        isButtonDisabled={disableButton}
        placeholder={t('Searcher.ph')}
      />

      <PopperAttom anchorRef={anchorRef} open={openPopper} setOpen={setOpenPopper} isCloseBlock={disableButton}>
        <Box
          component={generalsEnums.BoxDiv}
          sx={{
            width: anchorRef.current?.clientWidth,
            backgroundColor: colors.WHITE,
            border: `1px solid ${colors.BORDER_CCC}`,
            marginTop: '6px',
            padding: '6px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <CircularProgressAttom circularSize={circularSize}>
              <Font
                text={t('Searcher.loadingItems')}
                color={colors.BLACK}
                size={circularFontSize}
                lineHeight={circularLineHeight}
                isHead={generalsEnums.falseBoolean}
              />
            </CircularProgressAttom>
          ) : (
            <React.Fragment>
              {itemsToShow.map((item) => {
                return (
                  <Box
                    component={generalsEnums.BoxDiv}
                    key={item._id}
                    sx={{
                      width: '100%',
                    }}
                  >
                    <TsiButton
                      type={generalsEnums.buttonTypes.transparent}
                      handleAction={(e) => {
                        e.preventDefault();
                        handleClickItem(item);
                      }}
                      text={generalsEnums.emptyString}
                      isDisabled={false}
                      size={generalsEnums.emptyString}
                      lineHeight={generalsEnums.zeroNumber}
                      icon={
                        <Box
                          component={generalsEnums.BoxDiv}
                          sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Font
                            text={item.name}
                            color={colors.BLACK}
                            size={generalsEnums.fontSizes.size14.size}
                            lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                            isHead={generalsEnums.falseBoolean}
                          />

                          <Font
                            text={item.codes}
                            color={colors.BLACK_06}
                            size={generalsEnums.fontSizes.size14.size}
                            lineHeight={generalsEnums.fontSizes.size14.lineHeight}
                            isHead={generalsEnums.falseBoolean}
                          />
                        </Box>
                      }
                      br="0px 8px 8px 0px"
                      h="40px"
                    />
                  </Box>
                );
              })}

              {totalItems > 0 && (
                <button onClick={handleShowMore} style={{
                  background: 'none',
                  color: 'blue',
                  textDecoration: 'underline',
                  border: 'none',
                  padding: '8px',
                  marginRight: '9px',
                  alignSelf: 'end',
                }}>
                  +{totalItems} {t('Searcher.results')}
                </button>
              )}
            </React.Fragment>
          )}
        </Box>
      </PopperAttom>
    </Box>
  );
};

export default Searcher;
